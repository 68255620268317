import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api,api2} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";
import Select from 'react-select';



class Users extends Component {

  constructor(props) {

        super(props);

        this.state = {
            evaluacion:{
              codigo_postal:"",
              estado:"",
              colonia:"",
              municipio:"",
              direccion:""
            },
            colonias:[],
            validado:false,
             modal_mapa:false,
   
        };



    }

    componentDidMount(){
      let    cp=22850;
      let    final="https://maps.googleapis.com/maps/api/geocode/json?address=cp "+cp+" &components=country:MX&key=AIzaSyBabAw-F5nqoG6_Oq1xflUW3DYz7tE8TF8";  

   
    

  }

  buscar_direccion=(evt)=>{

    evt.preventDefault();
    let _self = this;
    let {evaluacion} = this.state;
    let nuevas_colonias=[];
    let    final="https://maps.googleapis.com/maps/api/geocode/json?address=cp "+evaluacion.codigo_postal+" &components=country:MX&key=AIzaSyBabAw-F5nqoG6_Oq1xflUW3DYz7tE8TF8";
    if(evaluacion.codigo_postal.length>0){

      axios.get(final)
           .then(res => {

               console.log(`estus de la busqueda ${res.data.status}`);
               let result=res.data.results[0];
               if(res.data.status=="OK"){

                /*validamos que exista postcode_localities en la respuesta 
                   si existe  llenamos el dropdown  con las opciones

                   si no existe ,  tomamos del address_components  la colonia existente
                    indices 
                    address_components[0].long_name codigo postal
                    address_components[1].long_name colonia
                    address_components[2].long_name municipio
                    address_components[3].long_name estado
                    address_components[4].long_name pais
                 */
                 console.log('postcode_localities',result.postcode_localities)
                 evaluacion.estado=result.address_components[3].long_name;
                 evaluacion.municipio=result.address_components[2].long_name;
                 
                 if (typeof result.postcode_localities === 'undefined') {
                  evaluacion.colonia=result.address_components[1].long_name;
                  nuevas_colonias.push({'label':result.address_components[1].long_name,value:result.address_components[1].long_name});

                 }else{

                  for (var i = result.postcode_localities.length - 1; i >= 0; i--) {
                        nuevas_colonias.push({'label':result.postcode_localities[i],'value':result.postcode_localities[i]})
                  }

                 }

                 _self.setState({
                    evaluacion : evaluacion,
                    colonias:nuevas_colonias,
                });

                 



               }else{
                swal.fire("Alerta!", 'Codigo Postal No valido', "warning");
                evaluacion.colonia='';
                evaluacion.estado='';
                 evaluacion.municipio='';
                _self.setState({
                    evaluacion : evaluacion,
                    colonias:nuevas_colonias
                });
               }
             });

    }


  }

  handleSubmit=(evt)=>{

    evt.preventDefault();
    let _self = this;
     let {evaluacion} = this.state;


     if(evaluacion.estado==''){
      swal.fire("Alerta!", 'Favor de agregar un Codigo Postal Valido', "warning");
      return;
     }

     if(evaluacion.colonia==''){
      swal.fire("Alerta!", 'Favor de Seleccionar una Colonia', "warning");
      return;
     }
       // swal.fire("Alerta!", 'asdfafadfafaa', "warning");
        _self.setState({
                    
                    validado:true,
                });


  }
  handeInputChange=(event)=>{
    let {evaluacion} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    evaluacion[name] = value;
   
    this.setState({
        evaluacion : evaluacion
    });
  }

   handleSelectChange=(select, name )=> {
      let {evaluacion} = this.state;
      
      const value = select === null ? null : select.value;
      evaluacion[name] = value;
      // console.log('value---->', value);
      // console.log('evaluacion---->',evaluacion);
      // console.log('name---->',name);
      this.setState({evaluacion : evaluacion});
    }



  render() {
    const {  evaluacion} = this.state;

    return (
      <div className="content">
        <Row>
          <Col >
            <Card>
              <CardHeader>
                <h1> Evaluación</h1>
              </CardHeader>
              <CardBody>
             <Form onSubmit={this.handleSubmit}>
             <div className='row'>

                      <Col className="pr-1" md="2">
                        <FormGroup>
                          <label>Codigo Postal</label>
                          <Input
                            placeholder="Codigo Postal"
                            type="text"
                            name="codigo_postal"
                            required
                            value={evaluacion.codigo_postal}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1 pt-md-3" md="2">
                       
                       <Button
                          className="btn-round"
                          color="primary"
                          type="button"
                           onClick={(evt) => { this.buscar_direccion(evt); }}>
                          Buscar
                        </Button>
                      </Col>


             </div>
             <div className='row'>

                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Estado</label>
                          <Input
                            placeholder="Estado"
                            type="text"
                            name="estado"
                            required
                            value={evaluacion.estado}
                            readOnly
                          
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>Municipio</label>
                          <Input
                            placeholder="Municipio"
                            type="text"
                            name="municipio"
                            required
                            value={evaluacion.municipio}
                            readOnly
                          
                          />
                        </FormGroup>
                      </Col>

                      <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="">Colonia</label>
                                <Select 
                                 options={this.state.colonias} 
                              
                                  value={this.state.colonias.filter(option => option.value == evaluacion.colonia)}   
                                onChange={ (select) => { this.handleSelectChange(select, 'colonia') } }
                                />
                            </div>
                        </div>
                        <Col className="pr-1" md="8">
                        <FormGroup>
                          <label>Direccion</label>
                          <Input
                            placeholder=" Ejemplo: Calle  X #123"
                            type="text"
                            name="direccion"
                            required
                            value={evaluacion.direccion}
                            onChange={this.handeInputChange}
                          />
                        </FormGroup>
                      </Col>

    
                     


             </div>
              <div className='row mt-5'>
              <div className="col-12 col-md-6" >
              <h4><b>Conceptos Informativos</b></h4>
              </div>
              <div className="col-12 col-md-6" >
              <h4><b>Resultado de la Revisión</b></h4>
              </div>

              <div className="col-12 col-md-6" >
                <p><b>1.- Clave Única de Vivienda CUV</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadio1">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadio2">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                  </div>
              </div>



              <div className="col-12 col-md-6" >
                <p><b>2.- Clave SHF del Avaluó</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions2si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions2" id="inlineRadioOptions2si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions2no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions2" id="inlineRadioOptions2no" value="option2"/>
                  </div>
              </div>




              <div className="col-12 col-md-6" >
                <p><b>3.- Fecha de Avaluó</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions3si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions3" id="inlineRadioOptions3si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions3no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions3" id="inlineRadioOptions3no" value="option2"/>
                  </div>
              </div>



               <div className="col-12 col-md-6" >
                <p><b>4.- Clave de la Unidad de Valuación  que Certificó el Avaluó (S.H.F.)</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions4si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions4" id="inlineRadioOptions4si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions4no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions4" id="inlineRadioOptions4no" value="option2"/>
                  </div>
              </div>



              <div className="col-12 col-md-6" >
                <p><b>5.- Clave del Controlador que Certificó el Avaluó</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions5si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions5" id="inlineRadioOptions5si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions5no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions5" id="inlineRadioOptions5no" value="option2"/>
                  </div>
              </div>

               <div className="col-12 col-md-6" >
                <p><b>6.- Clave del Valuador Profesional(S.H.F.)</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions6si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions6" id="inlineRadioOptions6si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions6no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions6" id="inlineRadioOptions6no" value="option2"/>
                  </div>
              </div>



              <div className="col-12 col-md-6" >
                <p><b>7.- Nombre del constructor  para el caso de Vivienda nueva</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions7si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions7" id="inlineRadioOptions7si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions7no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions7" id="inlineRadioOptions7no" value="option2"/>
                  </div>
              </div>

              <div className="col-12 col-md-6" >
                <p><b>8.-Propósito del Avaluó</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions8si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions8" id="inlineRadioOptions8si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions8no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions8" id="inlineRadioOptions8no" value="option2"/>
                  </div>
              </div>

              <div className="col-12 col-md-6" >
                <p><b>9.-Tipo de inmueble a valuar</b></p>
              </div>
              <div className="col-12 col-md-6" >
                  <div class="form-check form-check-inline">
                    <label class="form-check-label p-0 pr-2" for="inlineRadioOptions9si">Si</label>
                    <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions9" id="inlineRadioOptions9si" value="option1"/>
                  </div>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions9no">No</label>
                    <input class="form-check-input radio-no" type="radio" name="inlineRadioOptions9" id="inlineRadioOptions9no" value="option2"/>
                  </div>
              </div>


              <Col className="pr-1 pt-md-3" md="12">
                       
                       <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                           >
                          Validar
                        </Button>
                      </Col>
              </div>
             </Form>

             <hr/>
             {this.state.validado&&



             <div className="row mb-4">
             <div className="col-12 text-center" > <h1> Resumen de Resultados </h1> </div>
             <div className="col-12 col-md-6" >
             <div className='row'>
                  <div className="col-12 " >
                    <div class="form-check form-check-inline">
                      <label class="form-check-label p-0 pr-2" for="inlineRadioOptions10alto">Alto</label>
                      <input class="form-check-input radio-si"  type="radio" name="inlineRadioOptions10" id="inlineRadioOptions10alto" value="option1"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions10medio">Medio</label>
                      <input class="form-check-input radio-si" checked type="radio" name="inlineRadioOptions10" id="inlineRadioOptions10medio" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions10bajo">Bajo</label>
                      <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions10" id="inlineRadioOptions10bajo" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions10nulo">Nulo</label>
                      <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions10" id="inlineRadioOptions10nulo" value="option2"/>
                    </div>

                    <div class="form-check form-check-inline">
                     <p><b>Nivel de Confiabilidad del Avaluó</b></p>
                      
                    </div>
                  </div> 
                  <div className="col-12 " >
                    <div class="form-check form-check-inline">
                      <label class="form-check-label p-0 pr-2" for="inlineRadioOptions11alto">Alto</label>
                      <input class="form-check-input radio-si" checked type="radio" name="inlineRadioOptions11" id="inlineRadioOptions11alto" value="option1"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions11medio">Medio</label>
                      <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions11" id="inlineRadioOptions11medio" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions11bajo">Bajo</label>
                      <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions11" id="inlineRadioOptions11bajo" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2" for="inlineRadioOptions11nulo">Nulo</label>
                      <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions11" id="inlineRadioOptions11nulo" value="option2"/>
                    </div>

                    <div class="form-check form-check-inline">
                     <p><b>Nivel de Condicionales Aceptables de la Vivienda</b></p>
                      
                    </div>
                  </div>
                  <div className="col-12 " >
                    <div class="form-check form-check-inline">
                      <label class="form-check-label p-0 pr-2 invisible" for="inlineRadioOptions12alto">Alto</label>
                      <input class="form-check-input radio-si invisible" type="radio" disabled name="inlineRadioOptions12" id="inlineRadioOptions12alto" value="option1"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2 invisible" for="inlineRadioOptions12medio">Medio</label>
                      <input class="form-check-input radio-si invisible" disabled type="radio" name="inlineRadioOptions12" id="inlineRadioOptions12medio" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2 ajuste_label_radio" for="inlineRadioOptions12bajo">Si</label>
                      <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions12" id="inlineRadioOptions12bajo" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2 ajuste_label_radio" for="inlineRadioOptions12nulo">No</label>
                      <input class="form-check-input radio-no" checked type="radio" name="inlineRadioOptions12" id="inlineRadioOptions12nulo" value="option2"/>
                    </div>

                    <div class="form-check form-check-inline">
                     <p><b>Posibles Conductas Sancionables</b></p>
                      
                    </div>
                  </div>
                  <div className="col-12 " >
                    <div class="form-check form-check-inline">
                      <label class="form-check-label p-0 pr-2 invisible" for="inlineRadioOptions12alto">Alto</label>
                      <input class="form-check-input radio-si invisible" type="radio" disabled name="inlineRadioOptions12" id="inlineRadioOptions12alto" value="option1"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2 invisible" for="inlineRadioOptions12medio">Medio</label>
                      <input class="form-check-input radio-si invisible" disabled type="radio" name="inlineRadioOptions12" id="inlineRadioOptions12medio" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2 ajuste_label_radio" for="inlineRadioOptions12bajo">Si</label>
                      <input class="form-check-input radio-si" type="radio" name="inlineRadioOptions12" id="inlineRadioOptions12bajo" value="option2"/>
                    </div>
                    <div class="form-check form-check-inline">
                      <label class="form-check-label  p-0  pr-2 ajuste_label_radio" for="inlineRadioOptions12nulo">No</label>
                      <input class="form-check-input radio-no" checked type="radio" name="inlineRadioOptions12" id="inlineRadioOptions12nulo" value="option2"/>
                    </div>

                    <div class="form-check form-check-inline">
                     <p><b>Recomendaciones</b></p>
                      
                    </div>
                  </div>

                  <div className="col-12 text-center " >
                  <h3> Confiabilidad</h3>
                  <div class="progress">
                      <div class="progress-bar bg-nulo " role="progressbar" style={{width: '25%'}}  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> <b>Nulo</b></div>
                      <div class="progress-bar bg-bajo " role="progressbar" style={{width: '25%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"> <b>Bajo</b></div>
                      <div class="progress-bar bg-medio" role="progressbar" style={{width: '25%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"> <b>Medio</b></div>
                      <div class="progress-bar bg-alto d-none" role="progressbar" style={{width: '25%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"> <b>Alto</b></div>
                  </div>
                  </div>

             </div>
             </div>
             <div className="col-12 col-md-6" >
             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >Incidencia %</div>
              <div className="col-12 col-md-6 text-center" >Cantidad</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Calificación +</b></div>
             </div>

             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >73.65</div>
              <div className="col-12 col-md-6 text-center" >28</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Respuestas Congruentes</b></div>
             </div>

             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >35.2</div>
              <div className="col-12 col-md-6 text-center" >12</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Respuestas Correctas</b></div>
             </div>

             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >100</div>
              <div className="col-12 col-md-6 text-center" >2</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Respuestas Aceptables</b></div>
             </div>



             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >80</div>
              <div className="col-12 col-md-6 text-center" >8</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Dentro del Rango Aceptable</b></div>
             </div>

             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >50</div>
              <div className="col-12 col-md-6 text-center" >9</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Condición Aceptable</b></div>
             </div>



              <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >0</div>
              <div className="col-12 col-md-6 text-center" >0</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Cumple</b></div>
             </div>


             <div className='row mt-3'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" ></div>
              <div className="col-12 col-md-6 text-center" ></div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Calificación -</b></div>
             </div>


              <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >26.3</div>
              <div className="col-12 col-md-6 text-center" >10</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Respuestas no Congruentes</b></div>
             </div>

             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >64.70</div>
              <div className="col-12 col-md-6 text-center" >22</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Respuestas no Correctas</b></div>
             </div>


              <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >0</div>
              <div className="col-12 col-md-6 text-center" >0</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Respuestas no Aceptables</b></div>
             </div>

             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >20</div>
              <div className="col-12 col-md-6 text-center" >2</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Fuera de Rango Aceptable</b></div>
             </div>


             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >100</div>
              <div className="col-12 col-md-6 text-center" >1</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>No Cumple</b></div>
             </div>


             <div className='row'>
              <div className="col-12 col-md-6" >

              <div className='row'>
              <div className="col-12 col-md-6 text-center" >20</div>
              <div className="col-12 col-md-6 text-center" >2</div>
             </div>

              </div>
              <div className="col-12 col-md-6" ><b>Error Aritmético</b></div>
             </div>




             </div>
                  
             </div>
           }
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Users)
