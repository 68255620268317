import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import { API_URL } from '../../actions/_request';
import { api, api2 } from '../../actions/_request';
import _ from "lodash";

import swal from 'sweetalert2';

import * as actions from '../../actions/user';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";

import Select from 'react-select';


const data = [
    {id : '1', estado : 'Baja California' , ciudad : 'Tijuana' , codigo_postal : '22001', colonia : 'revolución', tipo_label : 'casa'},
    {id : '2', estado : 'Baja California' , ciudad : 'Tijuana' , codigo_postal : '22002', colonia : 'revolución', tipo_label : 'casa'},
    {id : '3', estado : 'Baja California' , ciudad : 'Mexicali' , codigo_postal : '22000', colonia : 'revolución', tipo_label : 'casa'},
    {id : '4', estado : 'Baja California' , ciudad : 'Tijuana' , codigo_postal : '22023', colonia : 'revolución', tipo_label : 'casa'},
    {id : '5', estado : 'Baja California' , ciudad : 'Tijuana' , codigo_postal : '22041', colonia : 'revolución', tipo_label : 'casa'},
    {id : '6', estado : 'Baja California' , ciudad : 'Tijuana' , codigo_postal : '22055', colonia : 'revolución', tipo_label : 'casa'},
]


class Users extends Component {

    constructor(props) {

        super(props);

        this.state = {
            orden : {
                image : null,
            },
            pages: null,
            loading: true,
            modal_mapa: false,
            filtro: 1,
            coordenadas: {
                lat: 0,
                lng: 0,
            },
            base_datos: this.props.auth.base_datos,
            estados: [],
            filtros: [],

        };
        this.table = React.createRef();


    }

    componentDidMount() {


        let _self = this;

    }

    addclaro=(e,name)=>{

		let {orden} = this.state;

		e.preventDefault();

		var file = e.target.files[0];
  		var reader = new FileReader();

  		reader.onload = (e) => {

		    var img = document.createElement("img");

		    img.onload = () => {

			    var canvas = document.createElement('canvas');
			    var ctx = canvas.getContext("2d");
			    ctx.drawImage(img, 0, 0);

			    var MAX_WIDTH = 500;
			    var MAX_HEIGHT = 677;
			    var width = img.width;
			    var height = img.height;

			    if (width > height) {
			        if (width > MAX_WIDTH) {
			    	    height *= MAX_WIDTH / width;
			          	width = MAX_WIDTH;
			        }
			    } else {
			        if (height > MAX_HEIGHT) {
			        	width *= MAX_HEIGHT / height;
			        	height = MAX_HEIGHT;
			        }
			    }
			    canvas.width = width;
			    canvas.height = height;
			    var ctx = canvas.getContext("2d");
			    ctx.drawImage(img, 0, 0, width, height);
			    
			    var dataurl = canvas.toDataURL("image/jpeg");
			    
			    // var toPush = [{'img':dataurl,'name':file.name}];

			    orden[name] = dataurl;

			    this.setState({ 
			    	orden : orden ,
			    });

                swal.fire("", 'Foto subida con exito', "success");
		    }

	    	img.src = e.target.result;

	  	}

	  	if (file) {
		    reader.readAsDataURL(file);
		}
	  	
	}

    mensajeUbicacion=()=>{
        swal.fire("", 'Ubicacion Guardada', "success");
    }
    mensajeVer=()=>{
        swal.fire("", 'Verificacion Guardada', "success");
    }



    render() {
        
        return (
            <div className="content">
                <Row>
                    <Col >
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xs={12}>
                                        <h4>Verificación</h4>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactTable

                                    className="-striped -highlight"
                                    columns={[
                                        { Header: '#', accessor: 'id', maxWidth: 150, minWidth: 150, style: { textAlign: 'center' } },
                                        { Header: 'Estado', accessor: 'estado' },
                                        { Header: 'Ciudad', accessor: 'ciudad' },
                                        { Header: 'Codigo Postal', accessor: 'codigo_postal' },

                                        { Header: 'Colonia', accessor: 'colonia' },
                                        { Header: 'Tipo', accessor: 'tipo_label' },


                                        {
                                            Header: 'Controles',
                                            filterable: false,
                                            sortable: false,
                                            minWidth:200,
                                            Cell: (row) => {
                                                return (
                                                    <div className="text-center">
                                                        <span className="btn-sm btn-primary" style={{ overflow: 'hidden', position : 'relative'}}>
                                                            Foto<input 
                                                                type="file" 
                                                                name="image" 
                                                                accept="image/*" 
                                                                onChange={ (e)=> { this.addclaro(e,'image')} } 
                                                                style={{ position: 'absolute', left: '0px' ,opacity: '0'}}
                                                                />
                                                        </span>
                                                        {' '}
                                                        <Button
                                                            color="primary"
                                                            className="btn-sm"
                                                            onClick={(evt) => this.mensajeUbicacion(evt)}>
                                                            UBICACIÓN
                                                        </Button>
                                                        
                                                        <Button
                                                            color="primary"
                                                            className="btn-sm"
                                                            onClick={(evt) => this.mensajeVer(evt)}>
                                                            Verificar
                                                        </Button>
                                                        

                                                    </div>
                                                )
                                            }
                                        }
                                    ]}
                                    //manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                                    data={data}
                                    // pages={pages} // Display the total number of pages
                                    // loading={loading} // Display the loading overlay when we need it
                                    // onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                                    filterable
                                    ref={this.table}
                                    defaultPageSize={25}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        auth: state.auth,
    }
};

export default connect(mapStateToProps, actions)(Users)
