import React from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 import {
 
    Button
} from 'reactstrap';

export class MapContainer extends React.Component {
constructor(props)
	{
		super(props)
        this.state = {
           coordenadas:props.coordenadas,
           editar:null,
          

        };
        this.mapClicked = this.mapClicked.bind(this); 
	}
mapClicked  ( mapProps , map , clickEvent ) {    
       this.setState({
			 coordenadas:{
            lat: clickEvent.latLng.lat().toFixed(7), 
            lng: clickEvent.latLng.lng().toFixed(7),
        },    
		});
        this.props.sendData(this.state.coordenadas);     
 

  }
  componentWillReceiveProps(nextProps)
	{
		let {coordenadas} = nextProps;
		let self = this;
		self.setState({
			coordenadas: coordenadas,
		});
	}

render() {
//   console.log(this.state.coordenadas);
    return (

      <Map google={this.props.google}
      
      center={{ lat: this.state.coordenadas.lat, lng: this.state.coordenadas.lng}} 
      style={{width: '98%', height: '100%'}} zoom={14}  >
 
 <Marker
    title={'Casilla'}
    name={'Casilla'}
   position={this.state.coordenadas} 

   />
     
 
        
      </Map>


    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyBabAw-F5nqoG6_Oq1xflUW3DYz7tE8TF8'
})(MapContainer)