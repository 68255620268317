import { api } from './_request';


 export const get = (data) => {
    return (dispatch) => {
                dispatch({
                type: 'LIST',
                    payload: data
                });
    }
 };

export const changePassword = (data , callback) =>{
    return (dispatch) => {

        api().put(`/password/${data.id}`, data)
        .then(function(response)
        {
            if(response.status === 200)
            {
                    dispatch({
                        type: 'PASS_UPDATE_SUCCESS',
                        payload: response.data
                    });     

                if(typeof callback === 'function') callback();
            }
            else
            {
                dispatch({ type: 'PASS_UPDATE_FAILURE' });
            }
        })
        .catch(function(error) {
            dispatch({ type: 'PASS_UPDATE_FAILURE' });
        });

    }
}

