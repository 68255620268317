import {api ,request,api2 } from './_request';

export const logout = (params) =>
{   
    return (dispatch) =>
    {   
        //Borrar de la base de datos
        api().post('/logout')
        .then(function(response)
        {
            if(response.status === 200)
            {
                //Borrar de la memoria local
                localStorage.removeItem('session_token_pos');
                dispatch({
                    type: 'DESCONECTADO',
                    payload: 'Desconectado'
                })
            }
        });
    }
}

export const checkToken = (params) =>
{
    return (dispatch) =>
    {
        let token = localStorage.getItem('session_token_pos');

        // token = JSON.parse(token);

        if(token !== null)
        {
           let AuthorizationToken = "Bearer "+token;

            request.get('user',
            {
                responseType: 'json',
                headers: {'Authorization': AuthorizationToken}
            })
            .then(function(response) 
            {
                if(response.status === 200)
                {
                    dispatch(
                    {
                        type: 'CONECTADO',
                        payload: token
                    });
                }
            })
            .catch(function(error) {
                localStorage.removeItem('session_token_pos');
            });
        }
    }
}

export const login = (params) =>
{   
    return (dispatch) =>
    {		
        request.post('/oauth/token', 
        {
            username        : params.email,
            password        : params.password,
            /*laravel*/
            // client_id       : 2,
            // client_secret   : '3F28cVJ2vQTr1MQyVtYXC8lutFAT1l1XES9Mcysh',
            // grant_type      : 'password'
        })
        .then(function(response)
        {
            if(response.status === 200)
            {
                localStorage.setItem('session_token_pos', response.data.token);
                dispatch(
                {
                    type: 'CONECTADO',
                    payload: response.data
                })
            }
        })
        .catch(function(error)
        {
            dispatch(
            {
                type: 'ERROR',
                payload: 'Datos incorrectos.'
            })
        })
    }
}

export const loginCard = (params) =>
{   
    return (dispatch) =>
    {       
       
        localStorage.setItem('session_token_pos', params);
        dispatch(
        {
            type: 'CONECTADO',
            payload: params
        })
            
    }
}

export const whoiam = () =>
{
    return (dispatch) => {

        api().get('/user')
        .then(function(response)
        {
            dispatch(
            {
                type: 'AUTH_WHOIAM_SUCCESS',
                payload: response.data
            });
        });

    }
}

export const actualizar_usuario = (usuario) =>
{
    return (dispatch) => {
        
       
            dispatch(
            {
                type: 'AUTH_WHOIAM_SUCCESS',
                payload: usuario
            });
       

    }
};


export const save = (item, callback) => {
    return (dispatch) => {

        dispatch({ type: 'SAVE' });

        api2().post('created_updated_asesores', item).then(function(response) {

            if(response.status === 200) {

                dispatch({
                    type: 'SAVE_SUCCESS',
                    payload: response.data
                });

                if(typeof callback === 'function') callback(response);

            }
            else{
                dispatch({ type: 'SAVE_FAILURE' });
            }

        })
        .catch(function(error) {
            dispatch({ type: 'SAVE_FAILURE' });
        });

    }
};



