const initialState =
{
    data: [],
    saving: false,
    usuarios:[],
    pass : false,
};

export default (state = initialState, action) => {
    switch(action.type)
    {
        case "PASS_UPDATE_SUCCESS":
            return { ...state, pass : action.payload , saving : false };
            break;
        case 'LIST':
            return {
                ...state,
                data: action.payload
            }
             break;
        case 'SAVE':
            return {
                ...state,
                saving: true
            }
             break;

        case 'SAVE_SUCCESS':
            return {
                ...state,
                saving: false,
                data: [...state.data, action.payload]
            }   
             break;    
            
        case 'UPDATE_SUCCESS':
            let newp = action.payload;
            return {
                ...state,
                saving: false,
                data: state.data.map((d, i) => { return (d.value == newp.value) ? newp : d })
            }
            break;

        default:
            return state;
    }
};