import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button
} from 'reactstrap';







import * as actions from '../../actions/user';
import {api} from '../../actions/_request';


import Mapa from "./google_maps";






class MapaModal extends React.Component
{
	constructor(props)
	{
		super(props)
        this.state = {
           coordenadas:props.coordenadas,
        };  
	}

	componentDidMount()
	{	


	}
   render()
	{
		return(


			<Modal isOpen={this.props.open} toggle={this.props.toggle} className="default modal-lg modal_mapa" style={{ height: '90%'}}>
		
                <ModalHeader toggle={this.props.toggle}>Mapa</ModalHeader>
                <ModalBody style={{width: '98%', height: '100%'}} >                    
                <Mapa  coordenadas={this.props.coordenadas} sendData={this.getData}/>
                </ModalBody>
                <ModalFooter>
                   
                    	<div>
                    		
                    		<Button color="danger" onClick={this.props.toggle}>
		                    	Cancelar
		                    </Button>
                    	</div>
                  
                </ModalFooter>
              
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
          auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(MapaModal)
