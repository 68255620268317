
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,Button
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart
} from "variables/charts.jsx";

import {API_URL} from '../actions/_request';
import {api,api_descarga} from '../actions/_request';
import _ from "lodash";

import * as actions from '../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';

class Dashboard extends React.Component {
  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
             data:[],
             };



       
    }


  render() {
     const {  pages, loading} = this.state;

              
    return (
        <div className="content">
        <Row>
          <Col >
            {
            //   <Card>
            //   <CardHeader>

            //   </CardHeader>
            //   <CardBody>
              

            //   </CardBody>
            // </Card>
          }
          </Col>
        </Row>

      </div>
    );
  }
}
function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Dashboard)
