/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Ver from "views/verificacion";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/Tables.jsx";
import Maps from "views/Map.jsx";
import UserPage from "views/User.jsx";
import UpgradeToPro from "views/Upgrade.jsx";



import lista_asesores from "views/asesores/lista.js";
import formulario_asesor from "views/asesores/formulario.js";


import lista_articulos from "views/articulos/lista.js";
import formulario_articulo from "views/articulos/formulario.js";

import lista_inmuebles from "views/inmuebles/lista.js";
import formulario_inmueble from "views/inmuebles/formulario.js";


import formulario_evaluacion from "views/evaluacion/formulario.js";



// var routes2 = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     icon: "nc-icon nc-bank",
//     component: formulario_movalmacen,
//     layout: "/app"
//   },
//   {
//     path: "/icons",
//     name: "Icons",
//     icon: "nc-icon nc-diamond",
//     component: Icons,
//     layout: "/app"
//   },
//   {
//     path: "/maps",
//     name: "Maps",
//     icon: "nc-icon nc-pin-3",
//     component: Maps,
//     layout: "/app"
//   },
//   {
//     path: "/notifications",
//     name: "Notifications",
//     icon: "nc-icon nc-bell-55",
//     component: Notifications,
//     layout: "/app"
//   },
//   {
//     path: "/user-page",
//     name: "User Profile",
//     icon: "nc-icon nc-single-02",
//     component: UserPage,
//     layout: "/app"
//   },
//   {
//     path: "/tables",
//     name: "Table List",
//     icon: "nc-icon nc-tile-56",
//     component: TableList,
//     layout: "/app"
//   },
//   {
//     path: "/typography",
//     name: "Typography",
//     icon: "nc-icon nc-caps-small",
//     component: Typography,
//     layout: "/app"
//   },
//   {
//     pro: true,
//     path: "/upgrade",
//     name: "Upgrade to PRO",
//     icon: "nc-icon nc-spaceship",
//     component: UpgradeToPro,
//     layout: "/app"
//   }
// ];


var routes = [

  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/app",
    exact: true,
  },
   {
    path: "/asesores",
    name: "Asesores",
    icon: "nc-icon nc-single-02",
    component: lista_asesores,
    layout: "/app",
    exact: true,
  },
  {
    path: "/asesores/nuevo",
    name: "Asesor nuevo",
    icon: "nc-icon nc-single-02",
    component: formulario_asesor,
    layout: "/app",
    exact: true
  },
  {
    path: "/verificacion",
    name: "Verificar",
    icon: "nc-icon nc-check-2",
    component: Ver,
    layout: "/app",
    exact: true
  },
  {
    path: "/asesores/editar/:id",
    name: "Editar Asesor",
    icon: "nc-icon nc-single-02",
    component: formulario_asesor,
    layout: "/app",
    exact: true
  },


  {
    path: "/inmuebles",
    name: "Asesores",
    icon: "nc-icon nc-single-02",
    component: lista_inmuebles,
    layout: "/app",
    exact: true,
  },


  {
    path: "/evaluacion",
    name: "Evaluacion",
    icon: "nc-icon nc-single-02",
    component: formulario_evaluacion,
    layout: "/app",
    exact: true,
  },























  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: Icons,
    layout: "/app"
  },
];



export default routes;
