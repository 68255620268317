import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";

import {API_URL} from '../../actions/_request';
import {api,api2} from '../../actions/_request';
import _ from "lodash";

import * as actions from '../../actions/user';
import swal from 'sweetalert2';
import moment from 'moment';
import Mapa from "./modal_mapa";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,Badge, Table 
} from "reactstrap";
import Select from 'react-select';
const bases = [
    {label: 'Fovissste', value: 1},
    {label: 'Externas', value: 2},
];

const externos = [
    {label: 'Externo 1', value: 1},
    {label: 'Externo 2', value:2},
];
class Users2 extends Component {
  render() {
    return (
        <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}

const requestData = (pageSize, page, sorted, filtered, base_datos,filtro) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var id='';
        var estado='';
        var ciudad='';
        var codigo_postal='';
         var colonia='';
          var tipo='';
          var codigo_postal='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        var patt = new RegExp("(si|s)", "i"); 
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
                       
            case "id":
              id=filtered[i].value;
              // console.log('id--->',filtered[i].value);
              break;
            case "estado":
              estado=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
              case "ciudad":
              ciudad=filtered[i].value;
              // console.log('empleado--->',filtered[i].value);
              break; 
            
          case "codigo_postal":
              codigo_postal=filtered[i].value;
              // console.log('rfc--->',filtered[i].value);
              break; 
               case "colonia":
              colonia=filtered[i].value;
              // console.log('rfc--->',filtered[i].value);
              break;
               case "tipo_label":
              tipo=filtered[i].value;
              // console.log('rfc--->',filtered[i].value);
              break;
          }
        }
              
              api2().get(`/get_inmuebles?estado=${estado}&filtro=${filtro}&base_datos=${base_datos}&colonia=${colonia}&tipo=${tipo}&ciudad=${ciudad}&codigo_postal=${codigo_postal}&id=${id}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};



class Users extends Component {

  constructor(props) {

        super(props);

        this.state = {
             pages: null,
             loading: true,
             modal_mapa:false,
             filtro:1,
            coordenadas:{
              lat: 0, 
              lng: 0,
            },   
            base_datos:this.props.auth.base_datos,
            estados:[],
            filtros:[],

        };

        this.fetchData = this.fetchData.bind(this);
        this.table = React.createRef();


    }

    componentDidMount(){
    

    let _self = this;
  
  
      api2().get("/get_estados")
      .then(function(response){
        if(response.status === 200){
         
          _self.setState({
    
            estados:response.data.estados,
           filtros:response.data.estados
          
            
          })
        }

      });
    

  }
     handleSelectChange=(select, name)=> {
        const value = select === null ? null : select.value;

        if(name=='base_datos'){
          this.setState({
                  [name]: value,
                  filtro:1,
                  filtros:parseInt(value)==1?this.state.estados:externos
              }, () => {
             this.fetchData(this.table.current.state)
          });
        }else{
          this.setState({
                [name]: value,
            }, () => {
           this.fetchData(this.table.current.state)
        });
        }

        
        
       
    }

     toggle=(evt,lat,long)=>
    {        
        let {modal_mapa} = this.state;
        this.setState({
            modal_mapa       : !modal_mapa,
             coordenadas:{
            lat: parseFloat(lat), 
            lng: parseFloat(long),
        } 
           
            
        });
    }
  

     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
      this.state.base_datos,
      this.state.filtro
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }


  render() {
    const {  pages, loading} = this.state;
    console.log('state----->',this.state)
    return (
      <div className="content">
        <Row>
          <Col >
            <Card>
              <CardHeader>
              <Row>
              <Col xs={4}>
            <div className="form-group" >
                      <Select  placeholder="" type="text"  name="base_datos" options={bases}  value={bases.filter(option => option.value == this.state.base_datos)}  onChange={ (select) => { this.handleSelectChange(select, 'base_datos') } } clearable={false} />
              </div>
              </Col>
              <Col xs={4}>
            <div className="form-group" >
                      <Select  placeholder="" type="text"  name="filtro" options={this.state.filtros} 
                       value={this.state.filtros.filter(option => option.value == this.state.filtro)}  
                       onChange={ (select) => { this.handleSelectChange(select, 'filtro') } } clearable={false} />
              </div>
              </Col>
              <Col className='text-right' xs={8}>
              <Link className="d-none" to="/app/usuarios/nuevo">
                <Button color="primary" className="mb-2" >
                    Agregar
                </Button>
              </Link>
              </Col>
              </Row>
              </CardHeader>
              <CardBody>
              <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                              
                                                  this.props.history.push('/app/usuarios/editar/'+rowInfo.original.id);
                                             
                                              
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                                {Header: '#', accessor: 'id',maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Estado', accessor: 'estado'},
                                {Header: 'Ciudad', accessor: 'ciudad'},
                                {Header: 'Codigo Postal', accessor: 'codigo_postal'},
                                
                                {Header: 'Colonia', accessor: 'colonia'},
                                {Header: 'Tipo', accessor: 'tipo_label'},

                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     // maxWidth:180,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm d-none" onClick={(evt)=>{this.props.history.push('/app/usuarios/editar/'+row.original.id); }}>
                                                    Editar
                                                </Button>
                                                <Button
                                                 color="primary" 
                                                 className="btn-sm" 
                                                 onClick={(evt)=>this.toggle(evt, row.original.latitud, row.original.longitud)}>
                                                    mapa
                                                </Button>
                                                {' '}
                                              
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                               ref={this.table}
                             
                              defaultPageSize={25} 
                              
                              
                             
    
                            />

              </CardBody>
            </Card>
          </Col>
        </Row>

        {
this.state.modal_mapa&&
<Mapa 
                 open={this.state.modal_mapa} 
                 toggle={this.toggle} 
                 coordenadas={this.state.coordenadas}
                />
}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
    return {
        user : state.user,
        auth:state.auth,
    }
};

export default connect(mapStateToProps, actions)(Users)
