import React from 'react';
import {connect} from 'react-redux';
import {Button, 
		Modal, 
		ModalHeader, 
		ModalBody, 
		ModalFooter,
		Label, 
		Input,
		FormGroup} from 'reactstrap';
import spinner from 'react-spinkit';

import swal from 'sweetalert2';

import * as actions from '../../actions/user';

import Spinner from 'react-spinkit';



const SubmitLoad = () => (
    <div className="p-2">
        <Spinner name="ball-clip-rotate-multiple" color="#b5271f" />
    </div>
);

class PassModal extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			currentPass : '',
			newPass : '',
			repitPass : '',
			match : true,
			action : 'changePassword',
		}

		this.handleSubmit           = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(event) {
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
  
        	
        this.setState({
            [name]: value,
        });

        let {newPass , repitPass} = this.state;

        if(name === 'newPass'){
        	if(value === repitPass && value.length>0){
        		this.setState({ match : false });
        		
        	}
        	else{
        		this.setState({ match : true });
        		
        	}
        }

        if(name === 'repitPass'){
        	if(value === newPass && newPass.length>0){
        		this.setState({ match : false });
        		
        	}
        	else{
        		this.setState({ match : true });
        		
        	}
        }
        
    	
    }

    asNothing(){
    	this.setState({
    		currentPass : '',
    		newPass  : '',
    		repitPass  : '' , 
    		match : false,
    	});
    	
    }

	handleSubmit(evt)
    {
    	evt.preventDefault();
    	let userId = this.props.auth.user.id;
        let {newPass , match , currentPass} = this.state;
        var self = this;
        let data = {id : userId ,password : newPass , currentPass : currentPass};

        if(!match && newPass !== '' && currentPass !== '' && newPass.length >= 6){
	        this.props[self.state.action](data, () => {
	        	
	                if (this.props.user.pass) {
	                    swal.fire({
	                     title : 'Contraseña',
	                     text : 'Actualizada',
	                     type : 'success',
	                     showConfirmButton: true,
	                     });
	                    self.asNothing();
	                    self.props.toggleE();
	                    
	                }
	                else{
	                	swal.fire({
	                     title : 'Contraseña',
	                     text : `La Contraseña actual es incorrecta`,
	                     type : 'error',
	                     showConfirmButton: true,
	                     });
	                   
	                }
	        });
   		}
   		else if(match){
   			swal.fire({
                title: 'Contraseña',
                text: "La nueva Contraseña no coincide con la confirmacion",
                type: 'error',
                // showCancelButton: true,
                // confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Continuar'
            });
   		}
   		else if(newPass.length <= 6){
   			swal.fire({
                title: 'Contraseña',
                text: " Demasiado corta  minimo 6 caracteres",
                type: 'error',
                // showCancelButton: true,
                // confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Continuar'
            });
   		}
  
    }

	render(){
		// console.log('User------>',this.props.auth)
		return(
			<Modal isOpen={this.props.open2} toggle={this.props.toggleE} className="default" >
	        	<form onSubmit={this.handleSubmit}  >
		            <ModalHeader toggle={this.props.toggleE}>Cambio de Contraseña</ModalHeader>
		            <ModalBody >
	                	<div className="row">
	                		<div className="col-1"></div>
		                    <div className="col-10">
		                        <FormGroup>
		                            <Label><i className="fa fa-unlock fa-lg mt-4"></i>&emsp;Contraseña actual</Label>
		                            <Input
		                            type="text"
		                            name="currentPass"
		                            autoComplete="new-password"
		                            placeholder="Contraseña Actual"
		                            value={this.state.currentPass}
		                            onChange={this.handleInputChange}
		                            className={this.state.currentPass.length == 0 ? "is-invalid" : "is-valid"}
		                            />
		                        </FormGroup>
		                    </div>
	                    </div>
	                    <div className="row"> 
	                    	<div className="col-1"></div>
		                    <div className="col-10">
		                        <FormGroup>
		                            <Label><i className="fa fa-lock fa-lg mt-4"></i> &emsp;Contraseña nueva</Label>
		                            <Input
		                            name="newPass"
		                            type="Password"
		                            value={this.state.newPass}
		                            onChange={this.handleInputChange}
		                            autoComplete="new-password"
		                            placeholder="Contraseña Nueva"
		                            className={this.state.newPass.length == 0 ? "is-invalid" : "is-valid"}
		                            />
		                        </FormGroup>
		                    </div> 
	                    </div>
	                    <div className="row"> 
	                    	<div className="col-1"></div>
		                    <div className="col-10">
		                        <FormGroup >
		                            <Label><i className="fa fa-lock fa-lg mt-4"></i> &emsp;Confirmar Contraseña</Label>
		                            <Input
		                            name="repitPass"
		                            type="Password"
		                            value={this.state.repitPass}
		                            onChange={this.handleInputChange}
		                            className={(this.state.match) ? "is-invalid" : "is-valid"}
		                            />
		                        </FormGroup>
		                    </div> 
	                    </div> 
		            </ModalBody>
		            <ModalFooter>
		                {
		                    <div>
		                        <Button color="primary" type="submit" >
		                           Guardar
		                        </Button>{' '}
		                        <Button color="secondary" onClick={this.props.toggleE}>
		                            Cancelar
		                        </Button>
		                    </div>
		                }
		            </ModalFooter>
	        	</form>
        	</Modal>
		)
	}
}

function mapStatetoProps(state, ownProps) {
	return {
		user : state.user,
		auth : state.auth,
	}
}

export default connect(mapStatetoProps, actions)(PassModal)